/* eslint-disable */

document.addEventListener("DOMContentLoaded", () => {

(function loadSyndicatedNewsletter($) {
  var $hostEl = $( '[data-syndicate]' ),
    debug = $hostEl.data( 'debug' ) || false,
    defaults = {
      'endpoint'   : 'https://www.propublica.org/',
      'element'    : '#newsletter-roadblock',
      'stylesheet' : 'https://assets.propublica.org/static/v5/css/deploy/syndicated-newsletter.css',
      'frequency'  : 3,
      'delay'      : 0,
      'scroll'     : 'body',
      'hostCookie' : 'bigstory'
    };
    const cookie = window.PP.utils.cookie;
    const funnel = window.PP.funnel;
    const newsletterList = "The Big Story";

    showLogic = function( hostCookie, frequency ) {
      // pageviews per session: ~ 2 - 2.5 -- Jan 2021

      let cookieVal = funnel.load_cookie();
      let signupStatus = funnel.is_signedup(newsletterList),
          sessionCount = funnel.get_session_count(),
          displayStatus = funnel.current_state.displayModal;

      if( debug ) {
        console.warn( "Modal syndication script is in debug mode." );

        console.log("current_state:");
        console.log(PP.funnel.current_state);

        return true;
      }

      if (signupStatus == 'true') {
        // used to be every 15 pageviews now every 6 sessions (12 - 15 pvs)
        frequency = frequency * 2;
      } else {
        // used to be every 9 pageviews now every 3 sessions (6 - 7.5)
        frequency = frequency;
      }

      if ( displayStatus &&
           ( sessionCount == 1 || sessionCount % frequency == 0 ) )
      {
        // recaptchaInit && recaptchaInit();
        return true;
      }
    },
    injectStylesheet = function( target, href ){
      var link = document.createElement( 'link' );

      link.setAttribute( 'rel', 'stylesheet' );
      link.setAttribute( 'type', 'text/css' );
      link.setAttribute( 'href', href );

      // Bail if this stylesheet is already `link`ed:
      for( l = target.document.styleSheets.length, i = 0; i < l; i++ ) {
        if( target.document.styleSheets[ i ].href == href ) {
          return;
        }
      }

      target.document.write( link.outerHTML );
    },
    injectScript = function( target, href ){
      var link = document.createElement( 'script' );

      link.setAttribute( 'src', href );

      // Bail if this stylesheet is already `link`ed:
      for( l = target.document.styleSheets.length, i = 0; i < l; i++ ) {
        if( target.document.styleSheets[ i ].href == href ) {
          return;
        }
      }

      target.document.write( link.outerHTML );
    },
    handleResult = function( data ) {
      var $hostEl  = $( this ),
        element    = $hostEl.data( 'element' ) || defaults.element,
        frequency  = $hostEl.data( 'frequency' ) || defaults.frequency,
        hostCookie = $hostEl.data( 'cookie' ) || defaults.hostCookie,
        $newsModal = $( data ).filter( element ),
        $injectEl  = $( '<iframe>', {
          class: "syndicated-modal",
          title: "Sign up for our newsletter?"
        }).css({
          position: 'fixed',
          left : 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1001,
          width: '100%',
          height: '100%'
        }),
        target = $hostEl.data( 'target' ),
        stylesheet = $hostEl.data( 'stylesheet' ) || defaults.stylesheet,
        iframew = function( iframe ) {
          if ( iframe.contentWindow ) {
            iframew = iframe.contentWindow;
          } else {
            if ( iframe.contentDocument && iframe.contentDocument.document ) {
              iframew = iframe.contentDocument.document;
            } else {
              iframew = iframe.contentDocument;
            }
          }
          return iframew;
        },
        iframewin;

      if( target ) {
        $newsModal
          .find( '[name="target"]' )
          .val( target );
      }

      if( showLogic( hostCookie, frequency ) === true  && $newsModal.length > 0 ) {
        $injectEl
          .append( $newsModal )
          .insertAfter( $hostEl );

        $newsModal
          .css({
            opacity: 0 // Hide the contents until the styles have loaded.
          })
          .addClass( 'collapsible-enhanced collapsible-expanded' )
          .removeClass( 'collapsible-collapsed' );

        iframewin = iframew( $injectEl[ 0 ] );

        iframewin.document.open();
        iframewin.document.write( $newsModal[ 0 ].outerHTML );

        injectStylesheet( iframewin, stylesheet );

        injectScript( iframewin, "https://www.google.com/recaptcha/api.js?onload=grecaptchaLoaded&render=6LcC1roqAAAAAK9equRCqsCagWPYzf79SKrxGFGY" );
        injectScript( iframewin, "https://assets.propublica.org/static/prod/v5/js/deploy/recaptcha-init.js" );
        injectScript( iframewin, "https://assets.propublica.org/static/prod/v5/js/deploy/user-funnel.js" );

        // send GA event
        if(window.dataLayer) {
          window.dataLayer.push({
            'event': 'trackGEvent',
            'gEventCategory': 'newsletter',
            'gEventAction': 'view',
            'gEventLabel': 'modal',
          });
        }

        var $modalForms = $( iframewin.document.body ).children().first();
        handleForms( hostCookie, $modalForms );

        iframewin.document.close();
      }
    },
    handleForms = function( hostCookie, $modal ) {
      var hostedModal = window.parent.document.querySelector( '.syndicated-modal' );

      $modal
        .on( 'click', function( e ) {
          if( e.target === this ) {
            hostedModal.parentNode.removeChild( hostedModal );
          }
          e.stopPropagation();
        })
        .find( '.close-btn, .close-link' )
          .on( 'click', function( e ) {
            funnel.deactivate_modal();
            funnel.save_cookie();
            hostedModal.parentNode.removeChild( hostedModal );
            e.preventDefault();
          });

      $( document ).on( 'keyup', function( e ) {
        if (e.keyCode == 27) {
          funnel.deactivate_modal();
          funnel.save_cookie();
          hostedModal.parentNode.removeChild( hostedModal );
          e.preventDefault();
        }
      });
       // Ensure all links inside the modal target the topmost context.
       $modal
       .find( 'a' )
       .attr( 'target', '_top' );
       $modal
        .find( '.newsletter-ajax' )
        .on( 'submit', function( e ) {
          var $form = $( this ),
            $submit = $form.find( 'input[type="submit"]' ),
            url = $form.attr( 'action' ),
            data = $form.serialize();

          $submit
            .val( 'Submitting…' )
            .attr( 'disabled', 'true ')
            .addClass( 'submitting' );

          $.ajax({
            type: 'POST',
            url: url,
            data: data,
            success: function( res ) {
              $form
                .removeClass( 'submitting', 'error' )
                .addClass( 'success' );

              if(parent.dataLayer) {
                parent.dataLayer.push({
                  'event': 'trackGEvent',
                  'gEventAction': 'subscribe',
                  'gEventCategory': 'newsletter',
                  'gEventLabel': 'modal'
                });
              }

              if (window.PARSELY && window.PARSELY.conversions) {
                window.PARSELY.conversions.trackNewsletterSignup('Modal');
              }

              $submit.val( "You’re signed up!" );
              funnel.add_newsletter_signup(newsletterList);
              funnel.deactivate_modal();
              funnel.save_cookie();
              console.log("current_state:");
              console.log(PP.funnel.current_state);
              console.log("Signed up for:");
              console.log(PP.funnel.get_newsletter_signups());
              setTimeout(function() {
                hostedModal.parentNode.removeChild( hostedModal );
              }, 2000);
            },
            error: function( res ) {
              $form
                .removeClass( 'submitting' )
                .addClass( 'error' );

              if(parent.dataLayer) {
                parent.dataLayer.push({
                  'event': 'trackGEvent',
                  'gEventAction': 'error',
                  'gEventCategory': 'newsletter',
                  'gEventLabel': 'modal'
                });
              }

              $submit
                .val( 'Check email address and try again' )
                .removeAttr('disabled');
            }
          });
          e.preventDefault();
        });
    };

  $hostEl.each(function() {
    var $el = $(this),
      delay = $el.data( 'delay' ) || defaults.delay,
      scrollset = $el.data( 'scroll' ),
      scroll = scrollset || defaults.scroll,
      endpoint = $el.data( 'endpoint' ) || defaults.endpoint,
      initializeModal = function() {
        $.ajax({
          type: 'GET',
          url: endpoint,
          context: $( this )[ 0 ],
          success: handleResult
        })
      },
      scrollPercentage = function( el ) {
        return el.getBoundingClientRect().bottom - window.innerHeight;
      },
      fireModal;

    if( scrollset ) {
      var scrollElement = document.querySelector( scroll );
      fireModal = function( e ) {
        if( scroll === "true" || scrollPercentage( e.data.el ) <= 0 ) {
          window.requestAnimationFrame(function() {
            setTimeout( initializeModal.bind( $el ), delay * 1000 );
            $( document ).off( "scroll", fireModal );
          });
        }
      }
      $( document ).on( "scroll", { 'el' : scrollElement }, fireModal );
    } else {
      setTimeout( initializeModal.bind( $el ), delay * 1000 );
    }
  });

})(jQuery);

});
